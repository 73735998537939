dl {
  margin-top: 0;
}

dd { 
  margin-left: 1.25rem;
  margin-bottom: 0.5em;
  & > * { display: inline; }
  &::before {
    content: "\2022";
    margin: auto 0.5em auto -1em;
  }
}

.resume__dd--no-bullet {
  &::before { content: ''; margin: auto; }
}
