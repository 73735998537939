/*
.nametag-animate {
  position: relative;
  animation-name: nametag-fade-in;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 1s;
}

@keyframes nametag-fade-in {
  from { left: 50vw; opacity: 0.01; }
  to   { left: 0; opacity: 1; }
}

.avatar-animate {
  position: relative;
  animation-name: avatar-fade-in;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.5s;
}

@keyframes avatar-fade-in {
  from { right: 50vw; opacity: 0.01; transform: rotate(-180deg) }
  to   { right: 0; opacity: 1; transform: rotate(0deg) }
}
*/

.MuiToolbar-root > .nav-menu { display: none; }
.mobile-menu-toggle { display: block; }

@media all and (min-width: 1140px) {
  .MuiToolbar-root > .nav-menu { display: flex; }
  .mobile-menu-toggle { display: none; }
}

@media all and (max-width: 500px) {
  #webDevTitle { display: none; }
}

.MuiToolbar-root > .nav-menu .MuiListItemIcon-root { 
  color: #FFF;
  min-width: auto;
  margin-right: 0.5em;
}
