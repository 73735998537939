.awssld__content > img {
  object-position: top;
}

.awssld__bullets {
  bottom: initial;
  top: -30px;
}

.awssld__controls button {
  position: fixed;
  top: 40vh;
}

/*
.awssld__controls .awssld__next { margin-right: 5vw; }
.awssld__controls .awssld__prev { margin-left: 5vw; }
*/

/*
.awssld__controls .awssld__next,
.awssld__controls .awssld__prev {
  padding-left: 3rem;
  padding-right: 3rem;
  background: rgba(0,0,0,0.5);
}
*/

.awssld {
  --organic-arrow-thickness: 12px;
  --organic-arrow-border-radius: 5px;
  --organic-arrow-height: 50px;
  --organic-arrow-color: #FFF;
  --control-button-width: 10%;
  --control-button-height: 200px;
  --control-button-background: rgba(0,0,0,0.5);
  --control-bullet-color: #AAA;
  --control-bullet-active-color: #DDD;
  --control-bullet-color: #FFF;
  --control-bullet-active-color: #f50057;
  --loader-bar-color: #3f51b5;
  --loader-bar-height: 25px;
}

/*
.awssld__bar {
  height: 25px;
  background-color: #3f51b5;
}
*/

/*
.awssld__bullets button {
  background: #FFF;
}
.awssld__bullets .awssld__bullets--active {
  background: #f50057;
}
*/

/*
.awssld {
  --slider-height-percentage: 60%;
  --slider-transition-duration: 700ms;
}
*/
